import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';
import { LayoutStoreService } from './layout/layout-store.service';
import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { UserVacancyListComponent } from './components/user-vacancy-list/user-vacancy-list.component';
import { UserVacancyItemComponent } from './components/user-vacancy-item/user-vacancy-item.component';
import { VacancyDetailComponent } from '@app/vacancies/vacancy-detail/vacancy-detail.component';
import { UserFilterComponent } from '@app/users/user-filter/user-filter.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { UserDetailComponent } from '@app/users/user-detail/user-detail.component';
import { UserCertificateItemComponent } from '@app/users/user-certificate-item/user-certificate-item.component';
import { UserCertificateListComponent } from '@app/users/user-certificate-list/user-certificate-list.component';
import { UserDrivinglicenseListComponent } from '@app/users/user-drivinglicense-list/user-drivinglicense-list.component';
import { UserEducationalrecordItemComponent } from '@app/users/user-educationalrecord-item/user-educationalrecord-item.component';
import { UserEducationalrecordListComponent } from '@app/users/user-educationalrecord-list/user-educationalrecord-list.component';
import { UserMasterItemComponent } from '@app/users/user-master-item/user-master-item.component';
import { UserMasterListComponent } from '@app/users/user-master-list/user-master-list.component';
import { UserSocialnetworkItemComponent } from '@app/users/user-socialnetwork-item/user-socialnetwork-item.component';
import { UserSocialnetworkListComponent } from '@app/users/user-socialnetwork-list/user-socialnetwork-list.component';
import { UserSpecificknowledgeItemComponent } from '@app/users/user-specificknowledge-item/user-specificknowledge-item.component';
import { UserSpecificknowledgeListComponent } from '@app/users/user-specificknowledge-list/user-specificknowledge-list.component';
import { UserSpokenlanguageItemComponent } from '@app/users/user-spokenlanguage-item/user-spokenlanguage-item.component';
import { UserSpokenlanguageListComponent } from '@app/users/user-spokenlanguage-list/user-spokenlanguage-list.component';
import { UserWorkexperienceItemComponent } from '@app/users/user-workexperience-item/user-workexperience-item.component';
import { UserWorkexperienceListComponent } from '@app/users/user-workexperience-list/user-workexperience-list.component';
import { UserAnnotationItemComponent } from '@app/users/user-annotation-item/user-annotation-item.component';
import { UserAnnotationListComponent } from '@app/users/user-annotation-list/user-annotation-list.component';
import { UserAvailabilityComponent } from '@app/users/user-availability/user-availability.component';
import { UserComputerskillItemComponent } from '@app/users/user-computerskill-item/user-computerskill-item.component';
import { UserComputerskillListComponent } from '@app/users/user-computerskill-list/user-computerskill-list.component';
import { UserOtherinformationComponent } from '@app/users/user-otherinformation/user-otherinformation.component';
import { VacancyFilterComponent } from '@app/vacancies/vacancy-filter/vacancy-filter.component';
import { ReminderListComponent } from './components/reminder-list/reminder-list.component';
import { ReminderItemComponent } from './components/reminder-item/reminder-item.component';
import { MyProfileComponent } from '@app/my-profile/my-profile.component';
import { CompanyDetailComponent } from '@app/companies/company-detail/company-detail.component';
import { FileDownloadHelper } from './helpers/FileDownloadHelper';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { FileItemComponent } from '@app/files/file-item/file-item.component';
import { FileListComponent } from '@app/files/file-list/file-list.component';
import { FileFilterComponent } from '@app/files/file-filter/file-filter.component';
import { FileItemUploadComponent } from '@app/files/file-item-upload/file-item-upload.component';
import { RegisterFileItemComponent } from './components/register-file-item/register-file-item.component';
import { RegisterFileListComponent } from './components/register-file-list/register-file-list.component';
import { RegisterFileUploadComponent } from './components/register-file-upload/register-file-upload.component';
import { CustomDatePickerModule } from './CustomDatePickerModule';
import { MyProfileChangePasswordComponent } from '@app/my-profile/my-profile-change-password/my-profile-change-password.component';
import { ChangePasswordComponent } from '@app/users/change-password/change-password.component';
import { UserComponent } from '@app/users/user/user.component';
import { CompanyComponent } from '@app/companies/company/company.component';
import { AccountHeaderComponent } from 'account/layout/account-header.component';
import { AccountFooterComponent } from 'account/layout/account-footer.component';
import { RegistrationUserFormComponent } from 'registration/registration-user-form/registration-user-form.component';
import { AccountLanguagesComponent } from 'account/layout/account-languages.component';
import { TenantChangeComponent } from 'account/tenant/tenant-change.component';
import { TenantChangeDialogComponent } from 'account/tenant/tenant-change-dialog.component';
import { UserVacancyFilterComponent } from './components/user-vacancy-filter/user-vacancy-filter.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserRoleHelper } from './helpers/UserRoleHelper';
import { CustomToggleSpanComponent } from './components/yes-no-span/custom-toggle-span.component';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        BsDropdownModule,
        ButtonsModule,
        AccordionModule,
        TooltipModule,
    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        UserDetailComponent,
        UserVacancyListComponent,
        UserVacancyItemComponent,
        VacancyDetailComponent,
        UserFilterComponent,
        UserWorkexperienceListComponent,
        UserWorkexperienceItemComponent,
        UserEducationalrecordListComponent,
        UserEducationalrecordItemComponent,
        UserMasterListComponent,
        UserMasterItemComponent,
        UserSpokenlanguageListComponent,
        UserSpokenlanguageItemComponent,
        UserSpecificknowledgeListComponent,
        UserSpecificknowledgeItemComponent,
        UserCertificateListComponent,
        UserCertificateItemComponent,
        UserDrivinglicenseListComponent,
        UserSocialnetworkListComponent,
        UserSocialnetworkItemComponent,
        UserAvailabilityComponent,
        UserOtherinformationComponent,
        UserComputerskillListComponent,
        UserComputerskillItemComponent,
        UserAnnotationListComponent,
        UserAnnotationItemComponent,
        VacancyFilterComponent,
        ReminderListComponent,
        ReminderItemComponent,
        MyProfileComponent,
        CompanyDetailComponent,
        FileSizePipe,
        FileListComponent,
        FileItemComponent,
        FileFilterComponent,
        FileItemUploadComponent,
        MyProfileChangePasswordComponent,
        ChangePasswordComponent,
        RegistrationUserFormComponent,
        RegisterFileListComponent,
        RegisterFileItemComponent,
        RegisterFileUploadComponent,
        UserComponent,
        CompanyComponent,
        AccountLanguagesComponent,
        AccountHeaderComponent,
        AccountFooterComponent,
        TenantChangeComponent,
        TenantChangeDialogComponent,
        UserVacancyFilterComponent,
        PageNotFoundComponent,
        CustomToggleSpanComponent,
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        CommonModule,
        RouterModule,
        NgxPaginationModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
        BsDropdownModule,
        AccordionModule,
        UserVacancyListComponent,
        UserVacancyItemComponent,
        VacancyDetailComponent,
        UserFilterComponent,
        ButtonsModule,
        UserDetailComponent,
        UserWorkexperienceListComponent,
        UserWorkexperienceItemComponent,
        UserEducationalrecordListComponent,
        UserEducationalrecordItemComponent,
        UserMasterListComponent,
        UserMasterItemComponent,
        UserSpokenlanguageListComponent,
        UserSpokenlanguageItemComponent,
        UserSpecificknowledgeListComponent,
        UserSpecificknowledgeItemComponent,
        UserCertificateListComponent,
        UserCertificateItemComponent,
        UserDrivinglicenseListComponent,
        UserSocialnetworkListComponent,
        UserSocialnetworkItemComponent,
        UserAvailabilityComponent,
        UserOtherinformationComponent,
        UserComputerskillListComponent,
        UserComputerskillItemComponent,
        UserAnnotationListComponent,
        UserAnnotationItemComponent,
        VacancyFilterComponent,
        ReminderListComponent,
        ReminderItemComponent,
        MyProfileComponent,
        CompanyDetailComponent,
        FileSizePipe,
        FileListComponent,
        FileItemComponent,
        FileFilterComponent,
        FileItemUploadComponent,
        MyProfileChangePasswordComponent,
        ChangePasswordComponent,
        RegistrationUserFormComponent,
        RegisterFileListComponent,
        RegisterFileItemComponent,
        CustomDatePickerModule,
        UserComponent,
        CompanyComponent,
        AccountLanguagesComponent,
        AccountHeaderComponent,
        AccountFooterComponent,
        TenantChangeComponent,
        TenantChangeDialogComponent,
        UserVacancyFilterComponent,
        TooltipModule,
        PageNotFoundComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [AppSessionService, AppUrlService, AppAuthService, AppRouteGuard, LayoutStoreService, FileDownloadHelper, UserRoleHelper],
        };
    }
}
