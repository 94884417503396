import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.WorkExperienceServiceProxy,
        ApiServiceProxies.JobDescriptionDictionaryServiceProxy,
        ApiServiceProxies.JobContractDictionaryServiceProxy,
        ApiServiceProxies.SpokenLanguageServiceProxy,
        ApiServiceProxies.CertificateServiceProxy,
        ApiServiceProxies.CertificateDictionaryServiceProxy,
        ApiServiceProxies.EducationalRecordServiceProxy,
        ApiServiceProxies.MasterServiceProxy,
        ApiServiceProxies.SpecificKnowledgeAppServiceBaseServiceProxy,
        ApiServiceProxies.SocialNetworkProfileServiceProxy,
        ApiServiceProxies.LicenseServiceProxy,
        ApiServiceProxies.AvailabilityServiceProxy,
        ApiServiceProxies.OtherInformationServiceProxy,
        ApiServiceProxies.ComputerSkillServiceProxy,
        ApiServiceProxies.NoteServiceProxy,
        ApiServiceProxies.TransportAvailabilityServiceProxy,
        ApiServiceProxies.LanguageDictionaryServiceProxy,
        ApiServiceProxies.LanguageLevelServiceProxy,
        ApiServiceProxies.KnowledgeLevelDictionaryServiceProxy,
        ApiServiceProxies.SocialNetworkDictionaryServiceProxy,
        ApiServiceProxies.NationDictionaryServiceProxy,
        ApiServiceProxies.ITCertificateDictionaryServiceProxy,
        ApiServiceProxies.EducationalDegreeDictionaryServiceProxy,
        ApiServiceProxies.LicenseTypeDictionaryServiceProxy,
        ApiServiceProxies.EducationalDegreeLevelServiceProxy,
        ApiServiceProxies.TransportDictionaryServiceProxy,
        ApiServiceProxies.AddressServiceProxy,
        ApiServiceProxies.OperationalUnitServiceProxy,
        ApiServiceProxies.CompanyServiceProxy,
        ApiServiceProxies.VacancyServiceProxy,
        ApiServiceProxies.EmployeesNumberServiceProxy,
        ApiServiceProxies.UserVacancyServiceProxy,
        ApiServiceProxies.MatchingStateDictionaryServiceProxy,
        ApiServiceProxies.ReminderServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.SelfApplicationServiceProxy,
        ApiServiceProxies.UserVacancyFileServiceProxy,
        ApiServiceProxies.GenderDictionaryServiceProxy,
        ApiServiceProxies.FileCategoryDictionaryServiceProxy,
        ApiServiceProxies.FolderServiceProxy,
        ApiServiceProxies.EducationalQualificationCreditDictionaryServiceProxy,
        ApiServiceProxies.JobAvailabilityDictionaryServiceProxy,
        ApiServiceProxies.EducationalDegreeDictionaryServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
