import { Injectable } from '@angular/core';
import { RoleConst } from '@shared/RoleConst';
import { UserDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class UserRoleHelper {
    constructor() {}

    isUserCentralRole(user: UserDto) {
        const r = user.roleName;
        return r === RoleConst.AdminRole || r === RoleConst.OperatorRole;
    }
}
