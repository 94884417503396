import { NgModule } from '@angular/core';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { itLocale, enGbLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
export function getCurrentLanguage(): string {
    if (abp.localization.currentLanguage.name) {
        return abp.localization.currentLanguage.name;
    }

    // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
    return 'en';
}
@NgModule({
    imports: [BsDatepickerModule.forRoot()],
    providers: [BsLocaleService],
})
export class CustomDatePickerModule {
    constructor(bsLocaleService: BsLocaleService) {
        /**
         * For next languages implementation, call define locale function to define new language.
         */
        defineLocale('it', itLocale);
        defineLocale('en', enGbLocale);
        bsLocaleService.use(getCurrentLanguage());
    }
}
