// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.staging.json',
    datePickerThemeClass: 'theme-dark-blue',
    recaptchaKey: '6Ldml28cAAAAAFpuffMYuvp-aQfNCjgi5IV90qbZ',
    defaultTenantId: 1
};

