import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/app/user', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then((m) => m.AccountModule), // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'registration',
        loadChildren: () => import('registration/registration.module').then((m) => m.RegistrationModule), // Lazy load registration module
        data: { preload: true },
    },
    {
        path: 'app',
        loadChildren: () => import('app/app.module').then((m) => m.AppModule), // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'matching',
        loadChildren: () => import('matching/matching.module').then((m) => m.MatchingModule), // Lazy load matching module
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {}
